import { YouTube } from '@sparemin/blockhead';
import React from 'react';
import * as S from './styles';
import UploadButton from './UploadButton';

interface YouTubeUploadButtonProps {
  onPress: () => void;
}

const YouTubeUploadButton: React.FC<YouTubeUploadButtonProps> = ({
  onPress,
}) => (
  <UploadButton
    aria-label="Upload files from YouTube"
    label="Youtube"
    logo={<S.YouTubeUploadIcon as={YouTube} />}
    onPress={onPress}
  />
);

export default YouTubeUploadButton;
